<template>
  <div class="SearchPage">
    <h1>{{$t('activity_comments_search')}}</h1>
    <div class="SearchPage__Section">
      <div class="SearchPage__Filters">
        <h3>{{$t('search_filters')}}</h3>
        <div class="SearchPage__Options">
          <div class="SearchPage__Bar">
            <SearchBar :placeholder="$t('comment_search_by_text')" ref="SearchBar" v-on:key-up="applyFilters()"/>
          </div>
          <div class="SearchPage__Select">
            <select v-model="userId" :class="{'option-undefined':userId == undefined}" v-on:change="applyFilters()">
              <option :value="undefined">{{$t('role_user')}}</option>
              <option v-for="m in communityMembers" :value="m.identifier">{{m.nickname}}</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </div>
          <div class="SearchPage__Select">
            <select v-model="activityId" :class="{'option-undefined':activityId == undefined}" v-on:change="applyFilters()">
                <option :value="undefined">{{$t('activity')}}</option>
                <option v-for="c in communityActivities" :value="c.identifier">{{c.title}}</option>
            </select>
            <i class="fa fa-caret-down"></i>
          </div>
          <div class="SearchPage__Actions">
            <Button color="accent" v-on:click="removeFilters()">
              {{$t('action_delete_filters')}}
            </Button>
            <Button v-on:click="wordDownload()" v-if="comments.objects.length > 0">
              {{$t('export_word')}}
            </Button>
          </div>
        </div>
      </div>
      <div class="SearchPage__Result" v-if="comments.objects.length > 0">
        <h3>{{$t('comments')}}</h3>
        <Comment
          v-for="c in comments.objects"
          :comment="c"
          :allowDelete="false"
          :allowLike="false"
          :allowDislike="false"
          :allowAttach="false"
          :allowTag="false"
          :allowReply="false"
          :theme="theme"
          :allowAddMedals="false"
          :allowPinComment="false"
          :allowChatButton="false"
          :allowCommentLink="true"
          :hideGamification="communityInfo.hideGamification"
          v-on:goActivity="goActivity($event)"
          />
            <Pagination
            v-model="page"
            :records="comments.count"
            :per-page="comments.pageSize"
            @paginate="handleCommentsPageChange"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import { mapGetters } from 'vuex';
  import store from '@/store';
  import { COMMUNITY_FETCH_COMMENTS_SEARCH, COMMUNITY_FETCH_COMMENTS_SEARCH_WORD,  COMMUNITY_FETCH_ACTIVITIES } from '@/store/community/activities.module';
  import { COMMUNITY_FETCH_MEMBERS } from '@/store/community/members.module';

  import Comment from '~/components/Comment';
  import Button from '~/components/Button';
  import SearchBar from '@/components/SearchBar';
  import Pagination from 'v-pagination-3';
  import FileSaver from 'file-saver';

  export default {
    name: 'SearchPage',

    components: {
      Button,
      SearchBar,
      Comment,
      Pagination
    },
    computed: {
      ...mapGetters([
        'communityActivities',
        'communityMembers',
        'origin',
        'communityInfo',
        'theme'
      ]),
    },
    data() {
      return {
        userId:undefined,
        activityId:undefined,
        page:1,
        pageSize:10,
        comments:{objects:[]},
        gettingData:false,
      };
    },
    methods: {
      handleCommentsPageChange(e){
        this.getComments()
      },
      async getComments(){
        let page = this.page
        if(this.page != 0) page--
        if(this.$refs.SearchBar.text == "" && this.userId == undefined && this.activityId == undefined){
          this.comments = {objects:[]}
          this.gettingData = false
          return
        }
        let data = {
          searchTxt:this.$refs.SearchBar.text,
          activityId: this.activityId,
          userId: this.userId,
          page: page,
          pageSize:this.pageSize
        }
        await store.dispatch(COMMUNITY_FETCH_COMMENTS_SEARCH,data)
        .then((data) => {
          for(let c of data.objects ){
            if(c.text.includes(this.$refs.SearchBar.text)){
              c.text = c.text.split(this.$refs.SearchBar.text).join("<span style='background:var(--primary-color);color:white'>"+this.$refs.SearchBar.text+"</span>")
            }
          }
          this.comments = data
          if (data.count == 0) {
            var vm = this;
            setTimeout(function() {
              Swal.fire({
                title: vm.$t('no_search_results'),
                icon: 'info'
              })
            }, 2000)
          }
        });
        this.gettingData = false
      },
      applyFilters(e,text){
        if(this.gettingData) return
        this.gettingData = true
        setTimeout(() => {
          this.getComments()
        }, 500);
      },
      removeFilters(){
        this.comments = {objects:[]}
        this.$refs.SearchBar.text = ""
        this.userId = undefined
        this.activityId = undefined
        this.page = 0
      },
      async wordDownload(){
        var vm = this;
        Swal.fire({
          title: vm.$t('msg_confirmation'),
          text: vm.$t('msg_file_download'),
          icon: 'warning',
          cancelButtonText: vm.$t('action_cancel'),
          confirmButtonText: vm.$t('msg_agree'),
          showCancelButton: true,
          customClass:{
            popup:'swal-customWarning'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            (async () => {
              let page = vm.page
              if(vm.page != 0) page--
              let data = {
                searchTxt:vm.$refs.SearchBar.text,
                activityId: vm.activityId,
                userId: vm.userId,
                page: page,
                pageSize:vm.pageSize
              }
              await store.dispatch(COMMUNITY_FETCH_COMMENTS_SEARCH_WORD,data)
              .then((data) => {
                var file = new Blob([data],  {type:'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                FileSaver.saveAs(file,vm.$t('comments_of_community',{community:vm.origin})+".docx");
              });
            })();
          }
        })
      },
      goActivity(e){
        this.$router.push({ name: 'activity.info', params: {notificationCommentId: e.identifier, notificationCommentPage:e.commentPage, id:e.activityId }})
      }
    },
    async mounted(){
      let priority = "myActivities"
      await store.dispatch(COMMUNITY_FETCH_ACTIVITIES,{priority})
      await store.dispatch(COMMUNITY_FETCH_MEMBERS,{order:"recent"})
    }
  };
</script>
<style lang="scss">
.SearchPage{
  .CommentV2 h1{
    font-size: 13px;
  }
}
</style>
<style lang="scss" scoped>
.SearchPage{
  background: white;
  padding: 20px;
  h1{
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #F4F4F4;
  }
  &__Section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__Options{
    display: flex;
  }
  &__Bar{
    width: 20%;
    border: 1px solid lightgray;
    margin: 10px 20px 10px 0;
    height: 40px;
    padding: 0px;
  }
  &__Select{
    width: 20%;
    margin: 10px 20px 10px 0;
    position: relative;
    height: 40px;
    select{
      width: 100%;
      height: 100%;
      padding: 0 5px;
      -webkit-appearance: none;
      border: 1px solid lightgray;
      font-size:15px;
      option{
        color: black;
      }
      &.option-undefined{
        color: #c3c3c3;
      }
    }
    i{
      position: absolute;
      right: 10px;
      top: 10px;
      color: var(--primary-color);
    }
  }
  &__Actions{
    margin: 10px 0;
    display: flex;
    button{
      margin-right: 15px;
    }
  }
  &__Result{
    width: 100%;
    border-top: 1px solid #F4F4F4;
    margin-top:10px;
    padding-top:10px;
    h3{
      margin-bottom: 10px;
    }
  }
  @media screen and ( max-width: 768px ) {
    &__Options{
      flex-direction: column;
      width: 100%;
    }
    &__Select, &__Bar{
      width:100%;
    }
  }
}
</style>
