<template>
  <div class="SearchBar">
    <input
      :id="'SearchBar-' + _uid"
      type="text"
      :placeholder="
        placeholder == 'Buscar por nickname'
          ? $t('filter_nickname')
          : placeholder
      "
      v-model="text"
      v-on:keyup="keyUp($event)"
    />
    <i class="fa fa-search"></i>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { verifyEmail } from '@/utils'
export default {
  name: 'SearchBar',
  props: {
    placeholder: {
      type: String,
      default: 'Buscar por nickname',
    },
    autocompleteListItems: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      text: '',
      hasSuggestions: false,
      autocompleteReady: false,
      isEmail: false,
      _uid: getCurrentInstance().uid,
      hasIdentifier: false
    }
  },
  mounted() {
    this.VerifyEmail(this.text)
    if (this.autocompleteListItems.length > 0)
      this.autocompleteList(
        document.getElementById('SearchBar-' + this._uid),
        this.autocompleteListItems
      )
  },
  updated() {
    this.VerifyEmail(this.text)
    if (this.autocompleteListItems.length > 0 && !this.autocompleteReady)
      this.autocompleteList(
        document.getElementById('SearchBar-' + this._uid),
        this.autocompleteListItems
      )
  },
  methods: {
    reset: function () {
      this.text = ''
    },
    keyUp(event) {
      const regex = /^[0-9]+$/;
      this.hasIdentifier = regex.test(this.text);
      this.$emit('key-up', event, this.text)
      if (this.autocompleteListItems.length == 0) {
        this.$emit('item-selected', {
          text: this.text,
          type: this.hasSuggestions ? 'filter' : (this.hasIdentifier ? 'identifier' : 'nickname'),
        })
      }
    },
    async VerifyEmail(email) {
      if (this.text !== '') {
        email = this.text.replace(/\s/g, '')
        this.isEmail = verifyEmail(email)
        if (this.isEmail == null) {
          this.isEmail = false
        } else {
          this.isEmail = true
        }
      } else {
        this.isEmail = false
      }
      return this.isEmail
    },
    itemSelected(hasSuggestions) {
      this.VerifyEmail(this.text)
      if (this.text == '') {
        this.hasSuggestions = false
      } else {
        const regex = /^[0-9]+$/;
        this.hasIdentifier = regex.test(this.text);
      }
      if (this.isEmail) {
        this.$emit('item-selected', { text: this.text, type: 'email' })
      } else {
        this.$emit('item-selected', {
          text: this.text,
          type: hasSuggestions ? 'filter' : (this.hasIdentifier ? 'identifier' : 'nickname'),
        })
      }
      if (hasSuggestions) this.reset()
    },
    autocompleteList(inp, arr) {
      this.autocompleteReady = true
      /*the autocomplete function takes two arguments,
      the text field element and an array of possible autocompleted values:*/
      var currentFocus
      var vm = this
      /*execute a function when someone writes in the text field:*/
      inp.addEventListener('input', function (e) {
        var a,
          b,
          i,
          val = this.value
        /*close any already open lists of autocompleted values*/
        closeAllLists()
        if (!val) {
          vm.itemSelected(true)
          return false
        }
        currentFocus = -1
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement('DIV')
        a.setAttribute('id', this.id + 'autocomplete-list')
        a.setAttribute('class', 'autocomplete-items')
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a)
        /*for each item in the array...*/
        vm.hasSuggestions = false
        for (i = 0; i < arr.length; i++) {
          const valUpperCase = val.toUpperCase()
          const textUpperCase = arr[i].toUpperCase()
          if (textUpperCase.includes(valUpperCase)) {
            const matchPosition = textUpperCase.indexOf(valUpperCase)
            const match = '<strong>' + arr[i].substr(matchPosition, valUpperCase.length) + '</strong>'
            const beforeMatch = arr[i].substr(0, matchPosition)
            const afterMatch = arr[i].substr(matchPosition + valUpperCase.length)
            b = document.createElement('DIV')
            b.innerHTML = beforeMatch + match + afterMatch
            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>"
            /*execute a function when someone clicks on the item value (DIV element):*/
            b.addEventListener('click', function (e) {
              /*insert the value for the autocomplete text field:*/
              inp.value = this.getElementsByTagName('input')[0].value
              /*close the list of autocompleted values,
                  (or any other open lists of autocompleted values:*/
              closeAllLists()
              vm.text = this.getElementsByTagName('input')[0].value
              vm.itemSelected(true)
            })
            a.appendChild(b)
            vm.hasSuggestions = true
          }
        }
        vm.itemSelected(false)
      })
      /*execute a function presses a key on the keyboard:*/
      inp.addEventListener('keydown', function (e) {
        var x = document.getElementById(this.id + 'autocomplete-list')
        if (x) x = x.getElementsByTagName('div')
        if (e.keyCode == 40) {
          /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
          currentFocus++
          /*and and make the current item more visible:*/
          addActive(x)
        } else if (e.keyCode == 38) {
          //up
          /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
          currentFocus--
          /*and and make the current item more visible:*/
          addActive(x)
        } else if (e.keyCode == 13) {
          /*If the ENTER key is pressed, prevent the form from being submitted,*/
          e.preventDefault()
          if (currentFocus > -1) {
            /*and simulate a click on the "active" item:*/
            if (x) x[currentFocus].click()
          }
        }
      })
      function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false
        /*start by removing the "active" class on all items:*/
        removeActive(x)
        if (currentFocus >= x.length) currentFocus = 0
        if (currentFocus < 0) currentFocus = x.length - 1
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add('autocomplete-active')
      }
      function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove('autocomplete-active')
        }
      }
      function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName('autocomplete-items')
        for (var i = 0; i < x.length; i++) {
          if (elmnt != x[i] && elmnt != inp) {
            x[i].parentNode.removeChild(x[i])
          }
        }
      }
      /*execute a function when someone clicks in the document:*/
      document.addEventListener('click', function (e) {
        closeAllLists(e.target)
      })
    },
  },
}
</script>

<style lang="scss">
.SearchBarFocusAnalysis {
  background: white !important;
  padding: 3px 9px !important;
  border-radius: 7px;
  border: none !important;
  height: 43px!important;
}
.SearchBarV2,
.SearchBarFocusAnalysis {
  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }

  /*when hovering an item:*/
  .autocomplete-items div:hover {
    background-color: #e9e9e9;
  }

  /*when navigating through the items using the arrow keys:*/
  .autocomplete-active {
    background-color: DodgerBlue !important;
    color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.SearchBar {
  display: flex;
  position: relative;
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  padding: 11px 15px;
  width: 100%;
  height: 100%;
  input {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    letter-spacing: 0.01em;
    color: #969696;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    &::placeholder {
      color: #b3b3b3;
      font-size: 15px;
    }
    &:focus {
      outline: none;
    }
  }
  i {
    color: var(--primary-color);
    position: absolute;
    top: 25%;
    right: 10px;
    font-size: 20px;
  }
}
</style>
